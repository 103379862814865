import React, { FC, lazy, Suspense } from "react";
import { useLocation } from "react-router-dom";
import { ChakraProvider, extendTheme, Flex, Spinner } from "@chakra-ui/react";

const AuthContextProvider = lazy(
  () => import("./components/contexts/AuthContext")
);
const AdminRoute = lazy(() => import("./components/routes/AdminRoute"));
const StoreContextProvider = lazy(
  () => import("./components/contexts/StoreContext")
);
const CustomerFacingScreen = lazy(
  () => import("./components/customer-facing-screen/CustomerFacingScreen")
);

const PublicRoute = lazy(() => import("./components/routes/PublicRoute"));
const OrderRoute = lazy(() => import("./components/routes/OrderRoute"));
const TableRedirect = lazy(() => import("./components//store/session/fixedQR/TableRedirect"));
const TimeZoneProvider = lazy(() => import("./components/contexts/TimeZoneContext"));

const App: FC = () => {
  const theme = extendTheme({
    // Theme configuration preserved as is
    shadows: { outline: "none" },
    fonts: {
      heading: "Monsterrat, sans-serif",
      body: "Monsterrat, sans-serif",
    },
    colors: {
      submit: {
        100: "#45f776",
        200: "#3fe06b",
        300: "#3ccf64",
        400: "#35b859",
        500: "#32a852",
        600: "#2b9447",
        700: "#23783a",
        800: "#1c612f",
        900: "#144521",
      },
      edit: {
        100: "#4058de",
        200: "#3b51cc",
        300: "#364aba",
        400: "#3244a8",
        500: "#2c3b91",
        600: "#243178",
        700: "#1c2661",
        800: "#151d4a",
        900: "#0e1330",
      },
      remove: {
        100: "#fa4646",
        200: "#ed4545",
        300: "#de4040",
        400: "#c73838",
        500: "#b53333",
        600: "#a12d2d",
        700: "#8f2828",
        800: "#782222",
        900: "#611a1a",
      },
      primary: {
        50: "#E6F0FA",
        100: "#C5DCFA",
        200: "#9DC2F0",
        300: "#73A8E6",
        400: "#5296E0",
        500: "#3182CE", // blue.500
        600: "#2B6CB0", // blue.600
        700: "#2C5282", // blue.700
        800: "#1E3B5E",
        900: "#162A40",
      },
      secondary: {
        50: "#E7F5EE",
        100: "#C4E6D3",
        200: "#9DD6B7",
        300: "#73C698",
        400: "#53B97F",
        500: "#38A169", // green.500
        600: "#2F855A", // green.600
        700: "#276749",
        800: "#1E5237",
        900: "#133929",
      },
      accent: {
        50: "#FDF0E6",
        100: "#FAD8C5",
        200: "#F6BE9F",
        300: "#F1A37A",
        400: "#EE8E5F",
        500: "#DD6B20", // orange.500
        600: "#C05621", // orange.600
        700: "#9C4221",
        800: "#7B341E",
        900: "#5C2819",
      },
      gray: {
        50: "#F7FAFC",
        100: "#EDF2F7",
        200: "#E2E8F0",
        300: "#CBD5E0",
        400: "#A0AEC0",
        500: "#718096",
        600: "#4A5568",
        700: "#2D3748",
        800: "#1A202C",
        900: "#171923",
      },
    },
    components: {
      Button: {
        baseStyle: {
          fontWeight: "medium",
          borderRadius: "md",
          _focus: {
            boxShadow: "none",
          },
        },
        variants: {
          solid: (props) => ({
            bg: `${props.colorScheme}.500`,
            color: "white",
            _hover: {
              bg: `${props.colorScheme}.600`,
              transform: "translateY(-2px)",
              boxShadow: "md",
            },
            _active: {
              bg: `${props.colorScheme}.700`,
              transform: "translateY(0)",
            },
            transition: "all 0.2s",
          }),
          outline: (props) => ({
            borderColor: `${props.colorScheme}.500`,
            color: `${props.colorScheme}.500`,
            _hover: {
              bg: `${props.colorScheme}.50`,
            },
            transition: "all 0.2s",
          }),
          ghost: (props) => ({
            color: `${props.colorScheme}.500`,
            _hover: {
              bg: `${props.colorScheme}.50`,
            },
            transition: "all 0.2s",
          }),
        },
      },
      IconButton: {
        baseStyle: {
          borderRadius: "md",
          _focus: {
            boxShadow: "none",
          },
        },
        variants: {
          solid: (props) => ({
            bg: `${props.colorScheme}.500`,
            color: "white",
            _hover: {
              bg: `${props.colorScheme}.600`,
              transform: "translateY(-2px)",
              boxShadow: "md",
            },
            _active: {
              bg: `${props.colorScheme}.700`,
              transform: "translateY(0)",
            },
            transition: "all 0.2s",
          }),
        },
      },
      Input: {
        baseStyle: {
          field: {
            borderRadius: "md",
          },
        },
        defaultProps: {
          focusBorderColor: "primary.500",
        },
        variants: {
          outline: {
            field: {
              _focus: {
                boxShadow: "0 0 0 1px var(--chakra-colors-primary-500)",
              },
            },
          },
        },
      },
      Textarea: {
        baseStyle: {
          borderRadius: "md",
        },
        defaultProps: {
          focusBorderColor: "primary.500",
        },
        variants: {
          outline: {
            _focus: {
              boxShadow: "0 0 0 1px var(--chakra-colors-primary-500)",
            },
          },
        },
      },
      Select: {
        baseStyle: {
          field: {
            borderRadius: "md",
          },
        },
        defaultProps: {
          focusBorderColor: "primary.500",
        },
        variants: {
          outline: {
            field: {
              _focus: {
                boxShadow: "0 0 0 1px var(--chakra-colors-primary-500)",
              },
            },
          },
        },
      },
      FormLabel: {
        baseStyle: {
          fontSize: "sm",
          fontWeight: "medium",
          color: "gray.700",
          marginBottom: "1",
        },
      },
      FormError: {
        baseStyle: {
          text: {
            fontSize: "xs",
            fontWeight: "medium",
          },
        },
      },
      Modal: {
        baseStyle: {
          dialog: {
            borderRadius: "lg",
            boxShadow: "xl",
          },
          header: {
            padding: "4",
            borderBottom: "1px solid",
            borderColor: "gray.100",
          },
          body: {
            padding: "4",
          },
          footer: {
            padding: "4",
            borderTop: "1px solid",
            borderColor: "gray.100",
          },
        },
      },
      Tabs: {
        variants: {
          line: {
            tab: {
              _selected: {
                color: "primary.600",
                borderColor: "primary.500",
              },
              _focus: {
                boxShadow: "none",
              },
            },
          },
        },
      },
      Card: {
        baseStyle: {
          container: {
            borderRadius: "lg",
            boxShadow: "sm",
            overflow: "hidden",
          },
          header: {
            padding: "4",
          },
          body: {
            padding: "4",
          },
          footer: {
            padding: "4",
            borderTop: "1px solid",
            borderColor: "gray.100",
          },
        },
      },
      Accordion: {
        baseStyle: {
          container: {
            borderRadius: "md",
            border: "1px solid",
            borderColor: "gray.200",
          },
          button: {
            _focus: {
              boxShadow: "none",
            },
          },
        },
      },
      Checkbox: {
        baseStyle: {
          control: {
            _focus: {
              boxShadow: "none",
              borderColor: "primary.500",
            },
          },
        },
        defaultProps: {
          colorScheme: "primary",
        },
      },
    },
    styles: {
      global: {
        body: {
          bg: "gray.50",
          color: "gray.800",
        },
        // Improved scrollbar styling
        "::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "::-webkit-scrollbar-track": {
          background: "rgba(240, 240, 240, 0.8)",
          borderRadius: "8px",
        },
        "::-webkit-scrollbar-thumb": {
          background: "rgba(100, 100, 100, 0.5)",
          borderRadius: "8px",
          border: "2px solid transparent",
          backgroundClip: "content-box",
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: "rgba(100, 100, 100, 0.7)",
          borderRadius: "8px",
          border: "2px solid transparent",
          backgroundClip: "content-box",
        },
      },
    },
  });

  const RenderRoute = () => {
    const location = useLocation();
    
    // Modified condition to handle both /order and /table paths
    if (location.pathname.startsWith("/order") || location.pathname.includes("/table")) {
      return (
        <Suspense
          fallback={
            <Flex height="100vh" width="full" align="center" justify="center">
              <Spinner
                size="xl"
                speed="0.9s"
                thickness="4px"
                color="orange.300"
              />
            </Flex>
          }
        >
            <OrderRoute />
        </Suspense>
      );
    } else if (
      location.pathname.includes("/admin") ||
      location.pathname === "/profile" ||
      location.pathname === "/email-verification" ||
      location.pathname === "/setting"
    ) {
      return (
        <Suspense
          fallback={
            <Flex height="100vh" width="full" align="center" justify="center">
              <Spinner
                size="xl"
                speed="0.9s"
                thickness="4px"
                color="orange.300"
              />
            </Flex>
          }
        >
          <StoreContextProvider>
            <AdminRoute />
          </StoreContextProvider>
        </Suspense>
      );
    } else if (location.pathname === "/customer") {
      return (
        <Suspense
          fallback={
            <Flex height="100vh" width="full" align="center" justify="center">
              <Spinner
                size="xl"
                speed="0.9s"
                thickness="4px"
                color="orange.300"
              />
            </Flex>
          }
        >
          <CustomerFacingScreen />
        </Suspense>
      );
    } else {
      return (
        <Suspense
          fallback={
            <Flex height="100vh" width="full" align="center" justify="center">
              <Spinner
                size="xl"
                speed="0.9s"
                thickness="4px"
                color="orange.300"
              />
            </Flex>
          }
        >
          <PublicRoute />
        </Suspense>
      );
    }
  };
  
  const AppRoute = () => {
    return (
      <>
        <RenderRoute />
        {/* <CookieConsent
          debug={true}
          style={{ background: "#212529" }}
          buttonStyle={{
            color: "#212529",
            background: "#fed435",
            fontSize: "13px",
          }}
          expires={365}
          buttonText="Allow Cookies"
        >
          We use cookies to improve your experience and functionality for the
          application, please read and agree with our{" "}
          <a href="#">Cookies Policy</a>
        </CookieConsent> */}
      </>
    );
  };

  return (
    <ChakraProvider theme={theme}>
      <Suspense
        fallback={
          <Flex height="100vh" width="full" align="center" justify="center">
            <Spinner
              size="xl"
              speed="0.9s"
              thickness="4px"
              color="orange.300"
            />
          </Flex>
        }
      >
        <AuthContextProvider>
          <Suspense fallback={<Flex height="100vh" width="full" align="center" justify="center"><Spinner size="xl" speed="0.9s" thickness="4px" color="orange.300" /></Flex>}>
            <TimeZoneProvider>
              <AppRoute />
            </TimeZoneProvider>
          </Suspense>
        </AuthContextProvider>
      </Suspense>
    </ChakraProvider>
  );
};

export default App;