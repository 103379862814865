import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import LanguageContextPrivider from "./components/contexts/LanguageContext";

// Add warning before closing or navigating away
window.addEventListener('beforeunload', (event) => {
  console.log("beforeunload");
  const message = "Are you sure you want to leave? Changes you made may not be saved.";
  event.preventDefault();
  event.returnValue = message;
  return message;
});


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop />
      <LanguageContextPrivider>
        <App />
      </LanguageContextPrivider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
